import { useState, useEffect, useCallback, useMemo } from 'react';
import React from 'react';
import { getDmImei, getDms, writesettings } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'
import {Loader} from '../../../UI/Loader/Loader';
import '../DmConfig.css'

export default React.memo (function DmPassport() {
    const parameter = useSelector((state) => state.dmimei.data[0]);
    const dispatch = useDispatch();
    const iduser = localStorage.getItem('iduser');

    // Централизованное состояние для всех модальных окон
    const [modalState, setModalState] = useState({
        isOpen: false,
        modalType: null, // 'name', 'type', или 'param'
        inputValue: '',
        hasError: false,
        currentParam: null,
        currentIndex: null,
        isLoading: false
    });

    // Мемоизированные данные
    const nameDm = useMemo(() => parameter?.imei_name || '...', [parameter?.imei_name]);
    const passportParam = useMemo(() => [
        parameter?.TimeSpeed100 || '...',
        parameter?.QSpeed100 || '...',
        parameter?.Ldm || '...',
        parameter?.S || '...'
    ], [parameter?.TimeSpeed100, parameter?.QSpeed100, parameter?.Ldm, parameter?.S]);

    const nameParam = useMemo(() => [
        "Время прохождения полного круга при скорости 100%, ч",
        "Минимальная норма вылива при скорости 100%, мм",
        "Длинна машины, м",
        "Орошаемая площадь, га"
    ], []);

    // Общие обработчики модального окна
    const handleModalOpen = useCallback((modalType, currentParam = null, currentIndex = null) => {
        let initialValue = '';
        if (modalType === 'name') initialValue = nameDm;
        else if (modalType === 'param' && currentIndex !== null) initialValue = passportParam[currentIndex];
        
        setModalState({
            isOpen: true,
            modalType,
            inputValue: initialValue,
            hasError: false,
            currentParam,
            currentIndex,
            isLoading: false
        });
    }, [nameDm, passportParam]);

    const handleModalClose = useCallback(() => {
        setModalState(prev => ({ ...prev, isOpen: false }));
    }, []);

    const handleInputChange = useCallback((e) => {
        setModalState(prev => ({ ...prev, inputValue: e.target.value }));
    }, []);

    // Унифицированная функция сохранения
    const handleSave = useCallback(async () => {
        const { modalType, inputValue, currentIndex } = modalState;
        
        if (!inputValue) {
            setModalState(prev => ({ ...prev, hasError: true }));
            return;
        }

        try {
            setModalState(prev => ({ ...prev, isLoading: true }));
            
            let num, value;
            switch(modalType) {
                case 'name':
                    num = 3;
                    value = inputValue;
                    break;
                case 'type':
                    num = 4;
                    value = inputValue;
                    break;
                case 'param':
                    num = 10 + currentIndex;
                    value = inputValue.replace(',', '.');
                    if (!isNaN(value) && value.trim() !== '') {
                        value = value.toString();
                    } else {
                        value = passportParam[currentIndex];
                    }
                    break;
                default:
                    return;
            }

            await dispatch(writesettings(parameter?.imei, value, num));
            await dispatch(getDmImei(parameter?.imei));
            if (modalType === 'name' || modalType === 'param') {
                await dispatch(getDms(iduser));
            }

            handleModalClose();
        } catch (error) {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        } finally {
            setModalState(prev => ({ ...prev, isLoading: false }));
        }
    }, [modalState, parameter?.imei, passportParam, dispatch, handleModalClose, iduser]);

    // Оптимизированный компонент для установки имени
    const SetNameDm = useCallback(() => (
        <div className='ParamInput'>
            <label>Название:</label>
            {modalState.isLoading && modalState.modalType === 'name' ? <Loader /> :
                <Button onClick={() => handleModalOpen('name')}>
                    <span className='buttonInput'>{nameDm}</span>
                </Button>
            }
        </div>
    ), [nameDm, handleModalOpen, modalState.isLoading, modalState.modalType]);

    // Оптимизированный компонент для выбора типа
    const SelectTypeDm = useCallback(() => {
        const value = parameter?.type_dm_id ? [(parameter.type_dm_id * 1 - 6)] : [];
        const arOptions = ['Секторная', 'Круговая'];
        
        const handleTypeChange = (e) => {
            const num = 4;
            const newValue = e.target.value;
            if (parameter?.type_dm_id !== (newValue * 1 + 6)) {
                dispatch(writesettings(parameter?.imei, (newValue * 1 + 6), num));
            }
        };

        return (
            <div className='ParamInput'>
                <label>Тип поливальной машины</label>
                <select className='DmSelect' value={value} onChange={handleTypeChange}>
                    {arOptions.map((text, index) => (
                        <option key={index} value={index}>{text}</option>
                    ))}
                </select>
            </div>
        );
    }, [parameter?.type_dm_id, parameter?.imei, dispatch]);

    // Оптимизированный компонент параметров
    const Parametr = useCallback(() => (
        nameParam.map((text, index) => (
            <div className='ParamInput' key={index}>
                <label>{text}</label>
                <Button onClick={() => handleModalOpen('param', text, index)}>
                    <span className='buttonInput'>{passportParam[index]}</span>
                </Button>
            </div>
        ))
    ), [nameParam, passportParam, handleModalOpen]);

    return (
        <div className='cardDm'>
            <h3>Общие настройки</h3>
            <SetNameDm />
            <SelectTypeDm />
            <div className='PassportSettings'>
                <Parametr />
            </div>

            {/* Единое модальное окно */}
            <Modal open={modalState.isOpen}>
                <div className='modalHeader'>
                    <h3>Введите новое значение</h3>
                    <svg onClick={handleModalClose} height="30" viewBox="0 0 200 200" width="30">    
                        <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                    </svg> 
                </div>
                <div className='modalInfo'> 
                    <label className='labelInfo'>
                        {modalState.modalType === 'name' 
                            ? 'Название дождевальной машины:' 
                            : modalState.currentParam}
                    </label>
                    <h4 className='labelInfoQuet'>
                        {modalState.hasError 
                            ? "Передумали?" 
                            : `Желаете установить ${modalState.inputValue}?`}
                    </h4>
                </div>
                <div className='contentControl'>
                    <input 
                        type={modalState.modalType === 'param' ? 'number' : 'text'} 
                        className='input' 
                        value={modalState.inputValue} 
                        onChange={handleInputChange}
                        style={{ border: modalState.hasError ? '1px solid red' : null }} 
                    />
                    <div className='modalButton'>
                        <Button onClick={handleModalClose}>Отмена</Button>
                        <Button onClick={handleSave}>Сохранить</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
});
import FooterNav from '../components/UI/FooterNav/FooterNav';
import UserList from '../components/UserList/UserList';

export default function Users() {

    return(
        <>
             <UserList />
             <FooterNav />
        </>
    )
}
import './DmRezIn.css';

export default function DmRezIn({status, islink} ) {

    const checkBit = (num, bitPosition) => {
        return (num & (1 << bitPosition)) !== 0;
      };

    const rezInRun = checkBit(status, 2)
    return(
        <> 
            {islink==='Online' && rezInRun ? (
            <div className='rezInInfo'>
                <p>IN</p>
            </div>
            ) : (null)}
        </>

    )
}
import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CircleWithLineAndTriangle from '../CircleWithLineAndTriangle';
import DmLink from '../UI/DmLink/DmLink';
import AnglePosition from '../UI/AnglePosition/AnglePosition';
import DmSignal from '../UI/DmSignal/DmSignal';
import DmStatus from '../UI/DmStatus/DmStatus';
import PressureSensor from "../UI/PressureSensor/PressureSensor";
import DmGun from '../UI/DmGun/DmGun';
import DmRezIn from '../UI/DmRezIn/DmRezIn';
import percent from '../../img/percent.svg'
import measuring from '../../img/measuring.svg'
import time from '../../img/time.svg'
import  './DmSummary.css';

const DmSummary = ({item})=>{
    const role = useSelector((state) => state.userdata?.role);
    console.log("DmSummary");
    console.log(item);
    const navigate = useNavigate();

    const [dmSummaryInfo, setDmSummaryInfo ] = useState({
        imei: '...',
        imei_name: '...',
        param_last_date: 0,
        angleCurrent: '...',
        speedSet: '...',
        qNorm: '...',
        timeMove: '...',
        username: '...',
        signalValue: '...',
        status: '...',
        adc: 0,
        pmax: 0
    });
    
    //проверку входных данных
    useEffect(() => {
        setDmSummaryInfo({
        imei: item?.imei || '...',    
        imei_name: item?.imei_name || '...',
        param_last_date: item?.param_last_date || 0,
        angleCurrent: item?.AngleCurrent || '...',
        speedSet: item?.Speed || '...',
        qNorm: item?.QNorma || '...',
        timeMove: item?.TimeMove || '...',
        username: item?.username || '...',
        signalValue: item?.Sig_Value || '...',
        status: item?.param4 || '...',
        adc: item?.param3 || 0,
        pmax: item?.PMax || 0,
        })
        
    },[item]);

    const OFFLINE_THRESHOLD_MINUTES = 5; // Порог оффлайна в минутах
    const serverTime = new Date(dmSummaryInfo.param_last_date);
    const browserTime = new Date();
    const diffInMilliseconds = Math.abs(browserTime - serverTime);
    const diffInMinutes = Math.floor(diffInMilliseconds/(1000*60));
    const islink = diffInMinutes > OFFLINE_THRESHOLD_MINUTES ? 'Offline' : 'Online';

 
    const handleClick = (e) => {
    e.preventDefault();
    navigate(`/dmdetal/${item.imei}`);
  };

    return(
      <section className='cardPivot' onClick={handleClick}>
         
      <div className='cardHeader'> 
        <div className='cardHeaderInfo'> 
           <div className='cardHeaderInfoP'> 
            <PressureSensor adc={dmSummaryInfo.adc}
                pmax={dmSummaryInfo.pmax} islink={islink} />
           </div>
          <div className='cardHeaderInfoIn'>
            <DmGun islink={islink} status={dmSummaryInfo.status} />
          </div>
          <div className='cardHeaderInfoIn'>
            <DmRezIn islink={islink} status={dmSummaryInfo.status} />
          </div>
        </div>
        <div className='cardHeaderLink'>
          <DmSignal signalValue={dmSummaryInfo.signalValue} 
          param_last_date={dmSummaryInfo.param_last_date} />             
          <DmLink islink={islink} />
        </div>
      </div>
      
      <div className='cardBody'>
          <div className='cardBodyCircle'> 
              <CircleWithLineAndTriangle data={item}  islink={islink}/>
              <AnglePosition angleCurrent={dmSummaryInfo.angleCurrent} />  
          </div>
      
          <div className='cardInfo'> 
                 <div className='cardInfoBlock'>
                     <div className='cardInfoName'>  
                         <h3>{dmSummaryInfo.imei_name}</h3>
                     </div>
                    <DmStatus status={dmSummaryInfo.status}  islink={islink} />
                 </div> 
                      <div className='cardBodyParam' > 
                         <div className='cardBodyPercent'> 
                             <img className='BodyImg' src={percent} alt="Result" />
                             <span>{dmSummaryInfo.speedSet}</span>
                         </div>
      
                         <div className='cardBodyMeasuring'> 
                             <img className='BodyImg' src={measuring} alt="Result" />
                             <span>{dmSummaryInfo.qNorm} мм</span>
                         </div>
      
                         <div className='cardBodyTime'>
                             <img className='BodyImg' src={time} alt="Result" />
                             <span>{dmSummaryInfo.timeMove} ч</span>
                         </div>
                     </div>                       
          </div>
       </div>
       <div className='cardFooter'>
       {role === 1 ? (<span>User: {dmSummaryInfo.username}imei: {dmSummaryInfo.imei}</span>) : null}
      </div>
      </section>
    )
}

export default DmSummary;


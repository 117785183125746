import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './page/LoginPage/LoginPage'
import RegisterPage from './page/RegisterPage/RegisterPage';
import RestorePage from './page/RestorePage/RestorePage';
import AboutProduct from './page/AboutProduct/AboutProduct';
import Dms from './page/Dms';
import Imeis from './page/Imeis'; 
import Users from './page/Users';
import DmDetals from './components/DmDetals/DmDetals';
import Navbar from './components/UI/Navbar/Navbar';
// import FooterNav from './components/UI/FooterNav/FooterNav';
import './App.css';

function App() {
  return (   
      <Router>
        <div className="App">
          <Navbar />
        <Routes>
          <Route exact path="/" element={<AboutProduct />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/restore" element={<RestorePage />} />
          <Route path="/dms"  element={<Dms />} />
          <Route path="/imeis"  element={<Imeis />} />
          <Route path="/users"  element={<Users />} />
          <Route path="/dmdetal/:id" element={<DmDetals />} />
        </Routes>
        {/* <FooterNav /> */}
        </div>
      </Router>
  );
};

export default App;

import { useState, useEffect, useMemo, useCallback } from 'react'
import { writesettings, getDmImei, btnReducerGeoLink } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import teach from '../../../../img/palochka.png' 
import '../DmConfig.css'
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'

export default function DmGNSS() {
    // Получаем текущее значение параметра из Redux
    const parameter = useSelector((state) => state.dmimei.data[0]);
    const role = useSelector((state) => state.userdata?.role);
    const dispatch = useDispatch();

        // Централизованное состояние модального окна
        const [modalState, setModalState] = useState({
            isOpen: false,
            paramName: '',
            inputValue: '',
            hasError: false,
            currentIndex: null
        });

    // Мемоизированные параметры
    const gnssParam = useMemo(() => [
        parameter?.OffsetNulN || '...',
        parameter?.PositionCShr || '...',
        parameter?.PositionCDl || '...'
    ], [parameter?.OffsetNulN, parameter?.PositionCShr, parameter?.PositionCDl]);

    const nameParam = useMemo(() => [
        "Смещение 0\u00b0 относительно севера, град",
        "Координата центра, широта",
        "Координата центра, долгота",
    ], []);

        // Обработчики модального окна
        const handleModalOpen = useCallback((name, index) => {
            setModalState({
                isOpen: true,
                paramName: name,
                inputValue: gnssParam[index],
                hasError: false,
                currentIndex: index
            });
        }, [gnssParam]);
    
        const handleModalClose = useCallback(() => {
            setModalState(prev => ({ ...prev, isOpen: false }));
        }, []);
    
        const handleInputChange = useCallback((e) => {
            setModalState(prev => ({ ...prev, inputValue: e.target.value }));
        }, []);

            // Оптимизированная функция сохранения
    const handleSave = useCallback(async () => {
        const { currentIndex, inputValue } = modalState;
        
        if (!inputValue) {
            setModalState(prev => ({ ...prev, hasError: true }));
            return;
        }

        try {
            let processedValue = inputValue;
            
            if (currentIndex === 0) {
                // Обработка смещения
                let offset = Math.round(inputValue.replace(',', '.') * 1);
                processedValue = Math.min(Math.max(offset, 0), 359);
            } else if (currentIndex === 1 || currentIndex === 2) {
                // Обработка координат
                const coordinata = inputValue.replace(',', '.');
                processedValue = isNaN(coordinata) || coordinata.trim() === '' 
                    ? gnssParam[currentIndex] 
                    : coordinata.toString();
            }

            await dispatch(writesettings(parameter?.imei, processedValue, 20 + currentIndex));
            await dispatch(getDmImei(parameter?.imei));
            
            handleModalClose();
        } catch (error) {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        }
    }, [modalState, parameter?.imei, gnssParam, dispatch, handleModalClose]);

        // Оптимизированный рендеринг параметров
        const GNSSparametr = useMemo(() => (
            nameParam.map((text, index) => (
                <div className='ParamInput' key={index}> 
                    <label>{text}</label> 
                    <Button onClick={() => handleModalOpen(text, index)}>
                        <span className='buttonInput'>{gnssParam[index]}</span>
                    </Button> 
                </div>
            ))
        ), [nameParam, gnssParam, handleModalOpen]);

            // Обработчик кнопки Geo-Link
    const handleGeoLinkClick = useCallback(() => {
        dispatch(btnReducerGeoLink(parameter?.imei));
        alert(`Команда "Обучение Geo-Link" отправлена`);
    }, [dispatch, parameter?.imei]);

    return (
        <div className='cardDm'>
            <h3>GNSS</h3>
            <div className='DmGnssConfig'>
                {GNSSparametr}
            </div>   
            
            {role === 1 && (
                <div className='GeoLink'>
                    <label>Обучение Geo-Link</label>
                    <div className='GeoLinkBtn'>    
                        <Button className='Btn' onClick={handleGeoLinkClick}>
                            <img src={teach} alt="Result" />
                        </Button>
                    </div>
                </div>
            )}
            
            {/* Единое модальное окно */}
            <Modal open={modalState.isOpen}>
                <div className='modalHeader'>
                    <h3>Введите новое значение</h3>
                    <svg onClick={handleModalClose} height="30" viewBox="0 0 200 200" width="30">    
                        <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                    </svg> 
                </div>
                <div className='modalInfo'> 
                    <label className='labelInfo'>{modalState.paramName}</label>
                    <h4 className='labelInfoQuet'>
                        {modalState.hasError ? "Передумали?" : `Желаете установить ${modalState.inputValue}?`}
                    </h4>
                    {modalState.currentIndex === 0 && (
                        <h4 className='labelInfoQuet'>
                            {"Смещение нуля может принимать значение от 0\u00b0 до 359\u00b0. С шагом в 1\u00b0."}
                        </h4>
                    )}
                </div>
                <div className='contentControl'>
                    <input 
                        type='number' 
                        className='input' 
                        value={modalState.inputValue} 
                        onChange={handleInputChange}
                        style={{ border: modalState.hasError ? '1px solid red' : null }} 
                    />
                    <div className='modalButton'>
                        <Button onClick={handleModalClose}>Отмена</Button>
                        <Button onClick={handleSave}>Сохранить</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
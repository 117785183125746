import { useState, useMemo, useCallback} from 'react'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDmImei, writesettings, btnSelect_send_sms } from '../../../../redux/actions';
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'
import {Loader} from '../../../UI/Loader/Loader';
import '../DmConfig.css'

export default React.memo(function DmSufety() {
    const parameter = useSelector((state) => state.dmimei?.data[0]);
    const dispatch = useDispatch(); 
    const [modal, setModal] = useState(false)
    const [inputvalue, setInputvalue] = useState();
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function onClickNumberOpen() {
        setInputvalue('');
        setModal(true);
        setHasError(false);
    }

    const onClickNumberSave=useCallback(async(inputvalue)=>{
        if (!inputvalue) {setHasError(true)} else {
        const num = 40; 
        setModal(false);
        setInputvalue('');
        setHasError(false);
        if (inputvalue.length===11) {
            setIsLoading(true);  
        try{await dispatch(writesettings(parameter?.imei, inputvalue.slice(1), num))
        .then(() => {dispatch(getDmImei(parameter?.imei))})
        .then(() => {console.log("Данные обновлены")})
        .catch((error) => {console.error("Ошибка при сохранении или обновлении данных: ", error)});
        } finally{
             setIsLoading(false);
        }
    } else {alert(`Номер не сохранен. Проверьте еще раз.`)}
      }    
    }, [parameter?.imei, dispatch]);
    
    const number = useMemo(() => {
            return parameter?.cmd11 ? 8 + parameter.cmd11 : '...';
        }, [parameter?.cmd11]); // Зависит только от cmd11, а не от всего parameter

    function SelectSendSms() {

    const value = parameter?.config_id ? [(parameter.config_id * 1 - 1)] : [];
    const arOptions = ['Нет', 'Да'];
    const options = arOptions.map((text, index) => {
        return <option key={index} value={index}>{text}</option>;          
    });
    function onClickSaveSendSMS(e) {
        const newValue = e.target.value;
        if (parameter?.config_id !== (newValue * 1 + 1)) {
            dispatch(btnSelect_send_sms(parameter?.imei, (newValue * 1 + 1)));
        }
    }
    return (
        <div className='ParamInput'>
            <label>Отправлять SMS при изменении состояния машины:</label>
            <select className='DmSelect' value={value} 
             onChange={onClickSaveSendSMS}>{options}</select> 
         </div> 
    )
 }

    return(
        <div className='cardDm'>
            <h3>Целостность</h3> 
            <div className='ParamInput'>
             <label>Номер телефона для оповещения:</label>
                {isLoading ? <Loader /> :
                <Button  onClick={()=>onClickNumberOpen()}>
                    <span className='buttonInput'>{number}</span>
                </Button>}    
            </div> 
            <div className='selectSms'>  
                <SelectSendSms /> 
            </div> 

            <Modal key={modal ? 'modal-open' : 'modal-closed'} open={modal}>
                     <div className='modalHeader'>
                        <h3>Введите новое значение</h3>
                        <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                            <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                        </svg> 
                    </div>
                    <label className='labelInfo'>Номер телефона для оповещения:</label>
                    <h4 className='labelInfoQuet'>
                    {hasError ? ("Передумали?") : ("Желаете установить "+`${inputvalue}`+'?')}</h4>
                    <div className='contentControl'>
                        <input type='number' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)}
                        style={{border: hasError ? '1px solid red' : null}} />

                     <div className='modalButton' >
                                <Button onClick={() => setModal(false)}>Отмена</Button>
                                <Button onClick={()=>onClickNumberSave(inputvalue)}>Сохранить</Button>
                     </div>
                     </div>
                </Modal>
        </div>
    )
});
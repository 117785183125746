import { useState, useEffect, useCallback } from 'react';
import { writesettings, getDmImei } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Checkbox from "../../UI/Checkbox/Checkbox";
import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';
import {Loader} from '../../UI/Loader/Loader';
import './DmBarrier.css'
import autostop from '../../../img/strelka_vpravo.png';
import auturevers from '../../../img/nazad_i_vpered.png'

export default function Barier () {
    const parameter = useSelector((state) => state.dmimei?.data[0]);
    const dispatch = useDispatch();
    console.log('DmBarier RENDER');
    useEffect(() => {    
        setBarrierState({
            angleCurrent: parameter?.AngleCurrent || '...',
            angleForward: parameter?.AngleForward || '...',
            angleRevers: parameter?.AngleRevers || '...',
    });           
        setCheckedFlag(parameter?.FlagBarrier === 0);
        setCheckedForw(parameter?.BarrierForwardAction === 1);
        setCheckedRev(parameter?.BarrierReversAction === 1);
    }, [parameter]);
    const [checkedFlag, setCheckedFlag] = useState();
    const [checkedForw, setCheckedForw] = useState();
    const [checkedRev, setCheckedRev] = useState();
    const [barrierState, setBarrierState] = useState([]);
    const [modal, setModal] = useState(false)   
    const [inputvalue, setInputvalue] = useState();
    const [param, setParam] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    function onClickWinOpen(mov) {
        setInputvalue('');
        setParam(mov);
        setModal(true);
    }
    const onClicAngleSave=async(inputvalue) => {
        setIsLoading(true);
            const num = param === 'forward' ? 71 : 72;
          try{ await dispatch(writesettings(parameter?.imei, inputvalue, num))
            .then(() => dispatch(getDmImei(parameter?.imei)))
            .then(() => {console.log("Данные обновлены");})
            .catch((error) => {console.error("Ошибка при сохранении или обновлении данных: ", error);});
       } finally {
        setIsLoading(false);
       }
            setModal(false);
        setInputvalue('');  
    }
        const handleCheckUseBarrier = useCallback((event)=>{
            const isChecked = event.target.checked;
            const inputvalue = 1 - parameter?.FlagBarrier; // Инверсия значения
            const num = 70;
            setCheckedFlag(isChecked);
            dispatch(writesettings(parameter?.imei, inputvalue, num))
            .then(() => dispatch(getDmImei(parameter?.imei)))
            .then(() => console.log("Данные обновлены"))
            .catch((error) => console.error("Ошибка при сохранении или обновлении данных: ", error));
        },[parameter,dispatch])
    
        const handleCheckMovForward = useCallback((event)=>{
            const isChecked = event.target.checked;
            const inputvalue =  3-parameter?.BarrierForwardAction;
            const num = 73;
            setCheckedForw(isChecked);
            dispatch(writesettings(parameter?.imei, inputvalue, num))
            .then(() => dispatch(getDmImei(parameter?.imei)))
            .then(() => console.log("Данные обновлены"))
            .catch((error) => console.error("Ошибка при сохранении или обновлении данных: ", error));
        },[parameter,dispatch])

        const  handleCheckMovRevers = useCallback((event)=> {
            const isChecked = event.target.checked;
            const inputvalue =  3-parameter?.BarrierReversAction;
            const num = 74;
            setCheckedRev(isChecked);
            dispatch(writesettings(parameter?.imei, inputvalue, num))
            .then(() => dispatch(getDmImei(parameter?.imei)))
            .then(() => console.log("Данные обновлены"))
            .catch((error) => console.error("Ошибка при сохранении или обновлении данных: ", error));
        }, [parameter,dispatch])

    return (
        <div className='BarrierCard'>
            <div  className='BarrierUse'>
                <label>Использовать програмные барьеры</label>   
                <div><Checkbox handleCheckboxChange={handleCheckUseBarrier}  checked={checkedFlag} id="checkbox4" /></div>
            </div>
            <TransitionGroup>
            {!checkedFlag ? 
                 (  <CSSTransition key="warningInfo" timeout={500}
                    classNames='barriOn'>
                    <div className='warningInfo'>
                    <h4>Внимание!</h4>
                    <div className='angleInfo'>
                        <p> Текущее положение: </p>
                        <h3>{barrierState.angleCurrent+'\u00b0'}</h3>
                    </div>
                 </div>
                 </CSSTransition>):(
                    <CSSTransition key="barrierOff" timeout={500} classNames="barriOff">
                    <div className='barrierOff'>
                         <h2>Барьеры отключены</h2>
                    </div>
                    </CSSTransition>)}
            </TransitionGroup>
                <div className='MovDirection'>
                <h3>Движение вперед</h3>
                <div className='BarrierAngle'>
                    <label>Барьер</label> 
                    {isLoading && param==='forward' ? <Loader /> :
                    <Button  onClick={()=>onClickWinOpen('forward')}>
                    <span className='buttonInput'>{barrierState.angleForward+'\u00b0'}</span>
                    </Button>}
                </div>
                 <h4>Действие при достижении барьера</h4>
                    <div className='ChoiceAction'>
                        <div className='VarAction'>
                            <img className='angleImg' src={autostop} alt="Result" />
                            <label>Автостоп</label>
                        </div>
                         <div><Checkbox handleCheckboxChange={handleCheckMovForward}  checked={checkedForw} id="checkbox5" /></div>
                         <div className='VarAction'>
                            <img className='angleImg' src={auturevers} alt="Result" />
                            <label>Автореверс</label>
                        </div>
                    </div>
                </div>

                <div className='MovDirection'>
                    <h3>Движение назад</h3>
                    <div className='BarrierAngle'>
                        <label>Барьер</label> 
                        {isLoading && param==='revers' ? <Loader /> :
                        <Button  onClick={()=>onClickWinOpen('revers')}>
                        <span className='buttonInput'>{barrierState.angleRevers+'\u00b0'}</span>
                        </Button>}
                    </div>
                     <h4>Действие при достижении барьера</h4>
                        <div className='ChoiceAction'>
                            <div className='VarAction'>
                                <img className='angleImg' src={autostop} alt="Result" />
                                <label>Автостоп</label>
                            </div>
                             <div><Checkbox handleCheckboxChange={handleCheckMovRevers}  checked={checkedRev} id="checkbox6" /></div>
                             <div className='VarAction'>
                                <img className='angleImg' src={auturevers} alt="Result" />
                                <label>Автореверс</label>
                            </div>
                        </div>
                     </div>
        
            <Modal open={modal}>
             <div className='modalHeader'>
                <h3>Введите новое значение</h3>
                <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                    <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                </svg> 
            </div>
            <label className='labelInfo'>Барьер при движении {param==='forward' ? ' вперед:' : ' назад:' } </label>
            <h4 className='labelInfoQuet'>Желаете установить {inputvalue} ? </h4>
            <div className='contentControl'>
                <input type='number' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)} />
             <div className='modalButton' >
                        <Button onClick={() => setModal(false)}>Отмена</Button>
                        <Button onClick={()=>onClicAngleSave(inputvalue)}>Сохранить</Button>
             </div>
             </div>
        </Modal>
        </div>
    )
}
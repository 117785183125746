import { useState ,useEffect} from 'react'
import {useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDms,getUsers } from '../../redux/actions';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import UserSummary from '../UserSummary/UserSummary';
import { LoaderList } from '../UI/Loader/Loader';
import magnifier from "../../img/magnifier.svg"
import './UserList.css';

export default function UserList() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token'); // Предположим, что токен хранится в localStorage
    const [input,setInput] =useState('');
    const dispatch = useDispatch();
    const [isLoading,setIsLoading]=useState(false);
    const role=useSelector((state) => state.userdata?.role);
    const users=useSelector((state) => state.usersdata?.users);
    console.log("users");
    console.log(users);
    const dms = useSelector((state) => state.dm?.data);
    console.log("data");
    console.log(dms);

    useEffect(() => {
            setIsLoading(true);
        const fetchGetDms = async () => {
            try { await dispatch(getDms());
                await dispatch(getUsers());
               setIsLoading(false);  
            } catch (error) { console.error('Ошибка при получении данных:', error);}
            };
            fetchGetDms();
    }, [dispatch]);

    useEffect(() => {
        if (!token) {navigate('/');}
        if (role === 1) {
            } else if (role) {
            navigate('/');
            }
    }, [role]);

    const getUserDms = (userId) => {
        return dms.filter((dm) => dm.id === userId) // создаем список предметов по пользователю
      };

      const filteredListUser = users && users?.filter(user =>
        input === '' || // Если поисковой запрос пуст, отображаем все элементы
         user.username.toLowerCase().includes(input.toLowerCase()) ||
         user.surname.toLowerCase().includes(input.toLowerCase()) ||
         user.name.toLowerCase().includes(input.toLowerCase()) ||
         user.patronymic.toLowerCase().includes(input.toLowerCase())
      );

    return (
        <div className='UserList'> 
            <div className='UserListHeader'> 
                <div className='UserListName'> 
                    <h2> Всего пользователей<span>{users?.length}</span></h2> 
                </div>
                <div className='UserListQuest'> 
                    <input type='text' className='UserListInput' placeholder='Введите название для поиска'
                        value={input}
                        onChange={(e)=>setInput(e.target.value)}
                    /> 
                    <img src={magnifier} alt="Result" className='UserListQuestImgMag'/>
                </div>
            </div> 
                {isLoading ? (<LoaderList />) : (
                <div className='UserListMain'>
                    <TransitionGroup>
                            {filteredListUser?.map(user=>
                                <CSSTransition
                                    key={user.id}
                                    timeout={500}
                                    classNames='userCard'
                                >
                            <UserSummary key={user.id} user={user} dmUser={getUserDms(user.id)} />
                            </CSSTransition>
                            )}
                    </TransitionGroup>
                </div> 
                )}
        </div>
    )
}
import axios from 'axios';


export const loginUser = (credentials) => async (dispatch) => {
  try {
    const response = await axios.post('https://dm-control.ru:5000/login', credentials);
    const { token } = response.data;
    console.log(response.data);

    localStorage.setItem('token', token);
    
    dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'LOGIN_FAILURE', payload: error.message });
  }
};


export const registerUser = (username,email,password,surname,name,patronymic) => async (dispatch) => {
  try {
    const response = await axios.post('https://dm-control.ru:5000/register', {username,email,password,surname,name,patronymic});

    dispatch({ type: 'REGISTER_SUCCESS', payload: response.data });
    alert(`Вы успешно зарегистрировались! Подтвердите регистрацию по ссылке в почте.`);
  } catch (error) {
    dispatch({ type: 'REGISTER_FAILURE', payload: error.message });
    alert(`Регистрация завершилась неудачей.`);
    console.log("error");
    console.log(error);
  }

};

export const logOut=()=>{
  return (dispatch, getState)=>{
    localStorage.removeItem('token');
    localStorage.removeItem('iduser');
    localStorage.removeItem('role');
    dispatch({ type: 'LOGIN_OUT', payload: null });
    dispatch({ type: 'FETCH_DMSDATA_CLEAR' });
    dispatch({ type: 'FETCH_DMIMEIDATA_CLEAR' });
    dispatch({ type: 'FETCH_USERDATA_CLEAR' });
    dispatch({ type: 'REGISTER_CLEAR' });
    dispatch({ type: 'FETCH_USERSDATA_CLEAR' });
  }
}

export const getDms = () => {
  console.log("getDms");
 // console.log({userid});
 // console.log({userrole});
  return async (dispatch) => {

  dispatch({ type: 'FETCH_DMSDATA_REQUEST' });
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get('https://dm-control.ru:5000/api/dms',{
      //params:{userid,userrole},
      headers:{
        Authorization: `Bearer ${token}` // Добавляем токен в заголовок
      },
    });
    dispatch({ type: 'FETCH_DMSDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_DMSDATA_FAILURE', payload: error.message });
  }
};
};

export const getUserData = () => {
  console.log("getUserData1");
  return async (dispatch) => {

  dispatch({ type: 'FETCH_USERDATA_REQUEST' });
  try {
    const token = localStorage.getItem('token');
    if (!token){return}
    console.log("getUserData");
    const response = await axios.get('https://dm-control.ru:5000/api/userdata',{
      headers:{
        Authorization: `Bearer ${token}` // Добавляем токен в заголовок
      },
    });
    dispatch({ type: 'FETCH_USERDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_USERDATA_FAILURE', payload: error.message });
  }
};
};

export const getDmImei = (imei) => {
  return async (dispatch) => {

  dispatch({ type: 'FETCH_DMIMEIDATA_REQUEST' });
  try {
    const response = await axios.get('https://dm-control.ru:5000/api/dmimei',{
      params:{imei},
    });

    dispatch({ type: 'FETCH_DMIMEIDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_DMIMEIDATA_FAILURE', payload: error.message });
  }
};
};
//команда вперед
export const btnReducerForward = (imei) => {
  console.log(imei);
  return async (dispatch) => {
  dispatch({ type: 'FETCH_BTNFORWARDDATA_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/btnforward',{imei});
    dispatch({ type: 'FETCH_BTNFORWARDDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_BTNFORWARDDATA_FAILURE', payload: error.message });
  }
};
};
//команда взад
export const btnReducerRevers = (imei) => {
  console.log(imei);
  return async (dispatch) => {
  dispatch({ type: 'FETCH_BTNREVERSDATA_REQUEST' });
  console.log('FETCH_BTNREVERSDATA_REQUEST' );
  try {
    console.log('FETCH_BTNREVERSDATA_SUCCESS' );
    console.log(imei);
    const response = await axios.post('https://dm-control.ru:5000/api/btnrevers',{imei});
    dispatch({ type: 'FETCH_BTNREVERSDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_BTNREVERSDATA_FAILURE', payload: error.message });
    console.log('FETCH_BTNREVERSDATA_FAILURE' );
  }
};
};

//команда стоп
export const btnReducerStop = (imei) => {
  console.log(imei);
  return async (dispatch) => {
  dispatch({ type: 'FETCH_BTNSTOPDATA_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/btnstop',{imei});
    dispatch({ type: 'FETCH_BTNSTOPDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_BTNSTOPDATA_FAILURE', payload: error.message });
  }
};
};

//команда включить режим c водой
export const checkReducerWater = (imei) => {
  console.log(imei);
  return async (dispatch) => {
  dispatch({ type: 'FETCH_CWDATA_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/checkwater',{imei});
    dispatch({ type: 'FETCH_CWDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_CWDATA_FAILURE', payload: error.message });
  }
};
};

//команда включить режим без воды
export const checkReducerNoWater = (imei) => {
  console.log(imei);
  return async (dispatch) => {
  dispatch({ type: 'FETCH_CNWDATA_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/checknowater',{imei});
    dispatch({ type: 'FETCH_CNWDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_CNWDATA_FAILURE', payload: error.message });
  }
};
};

// Установить параметр в начтройках
export const writesettings = (imei,inputvalue,num) => {
   console.log(imei);
   console.log(inputvalue);
   console.log(num);
  return async (dispatch) => {
  dispatch({ type: 'FETCH_WRITESETTINGSDATA_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/writesettings',{imei,inputvalue,num});
    dispatch({ type: 'FETCH_WRITESETTINGSDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_WRITESETTINGSDATA_FAILURE', payload: error.message });
  }
};
};

//команда обучение GeoLink
export const btnReducerGeoLink = (imei) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_BTNSTOPDATA_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/btnGeoLink',{imei});
    dispatch({ type: 'FETCH_BTNSTOPDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_BTNSTOPDATA_FAILURE', payload: error.message });
  }
};
};

//команда select_send_sms
export const btnSelect_send_sms = (imei,inputvalue) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_SELECTSENDSMSDATA_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/selectsendsms',{imei,inputvalue});
    dispatch({ type: 'FETCH_SELECTSENDSMSDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_SELECTSENDSMSDATA_FAILURE', payload: error.message });
  }
};
};

// Добавить новый IMEI
export const addimei = (inputvalue) => {
  console.log("addimei");
  console.log(inputvalue);
 return async (dispatch) => {
 dispatch({ type: 'FETCH_ADDIMEI_REQUEST' });
 try {
   const response = await axios.post('https://dm-control.ru:5000/api/addimei',{inputvalue});
   dispatch({ type: 'FETCH_ADDIMEI_SUCCESS', payload: response.data });
   alert(response.data.message);
   console.log(response.data.message);
 } catch (error) {
   dispatch({ type: 'FETCH_ADDIMEI_FAILURE', payload: error.message });
 }
};
};

// Удалить  IMEI
export const delimei = (inputvalue) => {
 return async (dispatch) => {
 dispatch({ type: 'FETCH_DELIMEI_REQUEST' });
 try {
   const response = await axios.post('https://dm-control.ru:5000/api/delimei',{inputvalue});
   dispatch({ type: 'FETCH_DELIMEI_SUCCESS', payload: response.data });
   alert(response.data.message);
 } catch (error) {
   dispatch({ type: 'FETCH_DELIMEI_FAILURE', payload: error.message });
 }
};
};

// Сброс  IMEI
export const resetimei = (inputvalue,userid) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_RESETIMEI_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/resetimei',{userid,inputvalue});
    dispatch({ type: 'FETCH_RESETIMEI_SUCCESS', payload: response.data });
    alert(response.data.message);
  } catch (error) {
    dispatch({ type: 'FETCH_RESETIMEI_FAILURE', payload: error.message });
  }
 };
 };
//получить список пользователей
 export const getUsers = () => {
console.log("getUsers");
  return async (dispatch) => {

  dispatch({ type: 'FETCH_USERSDATA_REQUEST' });
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get('https://dm-control.ru:5000/api/getusers',{
      headers:{
        Authorization: `Bearer ${token}` // Добавляем токен в заголовок
      },
    });
    console.log(response.data);
    dispatch({ type: 'FETCH_USERSDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_USERSDATA_FAILURE', payload: error.message });
  }
};
};

// перемещение  IMEI
export const relocate = (inputvalue,userid) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_RELOCATE_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/relocate',{userid,inputvalue});
    dispatch({ type: 'FETCH_RELOCATE_SUCCESS', payload: response.data });
    alert(response.data.message);
  } catch (error) {
    dispatch({ type: 'FETCH_RELOCATE_FAILURE', payload: error.message });
  }
 };
 };

 // Удалить  пользователя
export const deluser = (inputvalue) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_DELUSER_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/deluser',{inputvalue});
    dispatch({ type: 'FETCH_DELUSER_SUCCESS', payload: response.data });
    alert(response.data.message);
  } catch (error) {
    dispatch({ type: 'FETCH_DELUSER_FAILURE', payload: error.message });
  }
 };
 };

  // Редактировать  пользователя
export const edituser = (value,usernameNew,surnameNew,nameNew,patronymicNew) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_EDITUSER_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/edituser',{value,usernameNew,surnameNew,nameNew,patronymicNew});
    dispatch({ type: 'FETCH_EDITUSER_SUCCESS', payload: response.data });
    alert(response.data.message);
  } catch (error) {
    dispatch({ type: 'FETCH_EDITUSER_FAILURE', payload: error.message });
  }
 };
 };

  // Вспомнить пароль
export const restorepassword = (usermail) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_RESTOREUSER_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/restorepassword',{usermail});
    dispatch({ type: 'FETCH_RESTOREUSER_SUCCESS', payload: response.data });
    alert('Письмо для восстановления пароля отправлено на ваш email.');
  } catch (error) {
    console.log(error.message);
    dispatch({ type: 'FETCH_RESTOREUSER_FAILURE', payload: error.message });
  }
 };
 };

   // Изменить пароль
export const changepassword = (password,sendtoken) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_CHANGEPASSWORDDATA_REQUEST' });
  try {
    const response = await axios.post('https://dm-control.ru:5000/api/changepassword',{password,sendtoken});
    dispatch({ type: 'FETCH_CHANGEPASSWORDDATA_SUCCESS', payload: response.data });
    alert(`Пароль успешно изменен!`);
  } catch (error) {
    console.log(error.message);
    dispatch({ type: 'FETCH_CHANGEPASSWORDDATA_FAILURE', payload: error.message });
  }
 };
 };
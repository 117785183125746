import React from "react";
import './Loader.css'

export const Loader=()=><div className="loader" />

export const LoaderList=()=>{
    return (
        <div className="load">
            <div>P</div>
            <div>N</div>
            <div>I</div>
            <div>D</div>
            <div>A</div>
            <div>O</div>
            <div>L</div>
        </div>
    )
}





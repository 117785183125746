
import { useState ,useEffect} from 'react'
import {useSelector, useDispatch } from 'react-redux';
import { delimei, getDms, resetimei, getUsers, relocate } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import DmLink from '../UI/DmLink/DmLink';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import sbros from '../../img/sbros.png'; 
import delet from '../../img/udalit.png';
import relocation from '../../img/hand_over.png';
import magnifier from "../../img/magnifier.svg"
import './ImeiSummary.css';

export default function ImeiSummary({item}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userid=useSelector((state) => state.userdata?.id);
    const users=useSelector((state) => state.usersdata?.users);

    useEffect(() => {
    const fetchGetUsers = async () => {
        try {await dispatch(getUsers());  
        } catch (error) { console.error('Ошибка при получении данных:', error);}
    };
        fetchGetUsers();
}, [dispatch]);
   
    const [imeiSummaryInfo, setImeiSummaryInfo ] = useState({
        imei: '...',
        imei_name: '...',
        param_last_date: 0,
        username: '...',
    });
    
    //проверка входных данных
    useEffect(() => {
        setImeiSummaryInfo({
        imei: item?.imei || '...',    
        imei_name: item?.imei_name || '...',
        param_last_date: item?.param_last_date || 0,
        username: item?.username || '...',
        })
    },[item]);

    const OFFLINE_THRESHOLD_MINUTES = 5; // Порог оффлайна в минутах
    const serverTime = new Date(imeiSummaryInfo.param_last_date);
    const browserTime = new Date();
    const diffInMilliseconds = Math.abs(browserTime - serverTime);
    const diffInMinutes = Math.floor(diffInMilliseconds/(1000*60));
    const islink = diffInMinutes > OFFLINE_THRESHOLD_MINUTES ? 'Offline' : 'Online';

    const [action, setAction] = useState('');
    const [modal, setModal] = useState(false)
    const [inputModal, setInputModal] = useState('');
    const [selectName, setSelectName] = useState ('');
    function onClickWinOpen(value) {
        setAction(value);
        setInputModal('');
        setSelectName('');
        setModal(true);
    }
    async function onClickActionSave(value) {
        setModal(false);
        if (action === 'delet') {await dispatch(delimei(value))}
        else if (action === 'reset') {await dispatch(resetimei(value,userid))}
        await dispatch(getDms());
    }

    async function onClickRelocation(imei, newUser) {
        setModal(false);
        await dispatch(relocate(imei,newUser));
        await dispatch(getDms());
    }

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/dmdetal/${item?.imei}`);
      };

      const filteredUsers = users && users.filter(user =>
        inputModal === '' || // Если поисковой запрос пуст, отображаем все элементы
         user.username.toLowerCase().includes(inputModal.toLowerCase()) ||
         user.surname.toLowerCase().includes(inputModal.toLowerCase()) ||
         user.name.toLowerCase().includes(inputModal.toLowerCase()) ||
         user.patronymic.toLowerCase().includes(inputModal.toLowerCase())
      );
    return(
        <>
        <div className='imeiCard'>
            <div className='imeiCardHeader'>
                <strong onClick={handleClick} className='cardHeaderName'>{imeiSummaryInfo.imei}</strong>
                {/* {islink==='Offline' ? (imeiSummaryInfo.param_last_date) : (<DmLink islink={islink} />) } */}
                <DmLink islink={islink} />
            </div>
            <div className='imeiCardBody'>
                <div className='imeiCardBodyInfo'>
                    <div className='imeiInfoTitle'>
                    Владелец:<span> {imeiSummaryInfo.username}</span>
                    </div>
                    <div className='imeiInfoTitle'>
                        Название ДМ:<span> {imeiSummaryInfo.imei_name}</span>
                    </div>
                </div>
                <div className=' imeiCardBodyBtn'>
                    <button onClick={()=>onClickWinOpen('relocation')} className='imeiBtn'>
                        <img src={relocation} alt="Result" /> 
                    </button>
                    <button onClick={()=>onClickWinOpen('reset')} className='imeiBtn'>
                        <img src={sbros} alt="Result" /> 
                    </button>
                    <button onClick={()=>onClickWinOpen('delet')} className='imeiBtn'>
                        <img src={delet} alt="Result" /> 
                    </button>
                </div>
            </div>
        </div>  

        <Modal open={modal}>

                <div className='modalHeader'>
                { action === 'relocation' ? 
                   <div className='relocationModalHeader'>
                        <h3>Перемещение: </h3>  
                    </div>
                   : <h3>Подтвердите действие</h3> }
                    <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                        <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                    </svg> 
                </div>

            { action === 'relocation' && 
                <div className='relocationSelectMain'>
                    <h3>{imeiSummaryInfo.imei}</h3>
                    <h4>Выберите пользователя:</h4>
                        <div className='relocationSelectUser'> 
                            {filteredUsers.map(user=>
                            <p>
                                <button key={user.id} className = {selectName===user.id ? 'selectUserOn' : 'selectUserOff'}
                                onClick={() => setSelectName(user.id)}>{user.username} </button> - 
                                {user.surname} {user.name} {user.patronymic}
                            </p>
                            )}
                        </div>
                    <div className='relocationUserSearch'> 
                        <img src={magnifier} alt="Result" />
                        <input type='text' className='relocationInput' value={inputModal}
                        placeholder='Введите данные пользователя'
                        onChange={e=>setInputModal(e.target.value)} />
                    </div>    
                </div>
           }
             { action != 'relocation' && <> 
                    <label className='labelInfo'>Вы точно желаете {action==='reset' ? (' сбросить настройки?') : (
                     action==='delet' ? ' удалить IMEI?' : null) } </label>
                         <h4 className='labelInfoQuet'>{imeiSummaryInfo.imei}</h4>
                </>
                }
            <div className='contentControl'>
            { action === 'relocation' && <h4 className='relocationConfirm'>Подтвердите выбор</h4>}
                <div className='modalButton' >
                    <Button onClick={() => setModal(false)}>Отмена</Button>
                    { action === 'relocation' ?  <Button onClick={()=> onClickRelocation(imeiSummaryInfo.imei, selectName)}>Передать</Button>:  
                    <Button onClick={()=> onClickActionSave(imeiSummaryInfo.imei)}>Да</Button>}
                </div>
            </div>
        </Modal>
    </>
    )
}
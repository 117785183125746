import React, {useEffect, useState, useMemo} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDms } from '../../redux/actions';
import DmSummary from '../DmSummary/DmSummary';
import { LoaderList } from '../UI/Loader/Loader';
import magnifier from "../../img/magnifier.svg";
import './DmList.css';

const DmList = ()=>{
  const navigate = useNavigate();
  const token = localStorage.getItem('token'); // Предположим, что токен хранится в localStorage
  const [input,setInput] =useState('');
  const dispatch = useDispatch();
  const role = useSelector((state) => state.userdata?.role);
  const [isLoading,setIsLoading]=useState(false);
  useEffect(() => {
            if (token === null) { navigate('/'); // Перенаправление на общую страницу
            }
          }, [token, navigate]);

  useEffect(() => {
    setIsLoading(true);
    const fetchGetDms = async () => {
        try { await dispatch(getDms());
        } 
        catch (error) { console.error('Ошибка при получении данных:', error);  
        }
        finally {
          setIsLoading(false);
        }
    };
    fetchGetDms();
    // const intervalId = setInterval(fetchGetDms, 3000); 
    // Очищаем интервал при размонтировании компонента
    // return () => clearInterval(intervalId);
  }, [dispatch]);

    const datain = useSelector((state) => state);
    console.log(datain);
    console.log('загрузка dmlist');
    const data = useSelector((state) => state.dm?.data);
    console.log("data");  
    console.log(data);  

    // const filteredDms = data && data?.filter(item =>
    //   input === '' || // Если поисковой запрос пуст, отображаем все элементы
    //   item.imei_name.toLowerCase().includes(input.toLowerCase())
    // );
    // Оптимизированная фильтрация с useMemo
      const filteredDms = useMemo(() => {
        if (!data) return [];
        
        return data.filter(item => 
          item.imei_name?.toLowerCase().includes(input.toLowerCase())
        );
      }, [data, input]);



        return(
            <div className='DmList'> 
              <div className='DmListHeader'> 
                <div className='DmListName'> 
                  <h2>Оборудование<span>{data?.length}</span></h2> 
                </div>
                <div className='DmListQuest'> 
                  <input type='text' className='DmListInput' placeholder='Введите название ДМ для поиска'
                    value={input}
                    onChange={(e)=>setInput(e.target.value)}
                  /> 
                  <img src={magnifier} alt="Result" className='DmListQuestImgMag'/>
                </div>
              </div> 
              {isLoading ? (<LoaderList />) : (
                      <div className='DmListMain'>  
                          {/* {filteredDms.map(item=><DmSummary item={item} key={item.id} />)} */}
                          {filteredDms.map(item => (
                            <DmSummary 
                              item={item} 
                              key={`${item.id}-${item.imei_name}`} // Улучшенный ключ
                            />
                          ))}

                      </div>
              )}
            </div> 
        )
    }
export default DmList;
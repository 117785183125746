import { useState, useEffect } from 'react';
import { writesettings, getDmImei } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Checkbox from '../../UI/Checkbox/Checkbox';
import Modal from '../../UI/Modal/Modal';
import Button from '../../UI/Button/Button';
import './DmGunSectors.css';
import angle from '../../../img/angle.svg';

// Компонент: строка - сектор
function SectorRow({ sector, onEdit }) {
    return (
        <tr className="sector">
            <td><span>{sector.id}</span></td>
            <td>
                <Button onClick={() => onEdit(sector, 'begin')}>
                    <span className="btnSector">{sector.beginAngle + '\u00b0'}</span>
                </Button>
            </td>
            <td>
                <p>-</p>
            </td>
            <td>
                <Button onClick={() => onEdit(sector, 'end')}>
                    <span className="btnSector">{sector.endAngle + '\u00b0'}</span>
                </Button>
            </td>
        </tr>
    );
}

function Sectors({ sectors, onEdit }) {
    return (
        <>
            {sectors.map((sector) => (
                <SectorRow key={sector.id} sector={sector} onEdit={onEdit} />
            ))}
        </>
    );
}

export default function DmGunSectors() {
    const parameter = useSelector((state) => state.dmimei?.data[0]);
    const dispatch = useDispatch();

    const [gunsectors, setGunsectors] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentSector, setCurrentSector] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [editType, setEditType] = useState(null);
    // const [mode, setMode] = useState();
    const [checked, setChecked] = useState();
    const [angleCurrent, setAngleCurrent] = useState();
    useEffect(() => { //генерим массив из 9 элементов - секторов
        if (parameter) {
            const createSectors = (count) =>
                Array.from({ length: count }, (_, index) => ({
                    id: `${index + 1}.`,
                    beginAngle: parameter[`sectorOn${index + 1}`] || '...',
                    endAngle: parameter[`sectorOff${index + 1}`] || '...',
                }));
            setGunsectors(createSectors(9));

            // Установка начального состояния чекбокса и режима
            // setMode(parameter.FlagGanUse === 0);
            setChecked(parameter.FlagGanUse === 0);
            setAngleCurrent(parameter?.AngleCurrent || '...')
        }
    }, [parameter]);

    // Открытие модального окна
    function openModal(sector, type) {
        setCurrentSector(sector);
        setEditType(type);
        setInputValue(''); // Сброс значения
        setModalOpen(true);
    }

    // Сохранение нового значения сектора
    function saveSectorValue() {
        if (currentSector && editType) {
            const updatedSectors = gunsectors.map((sector) =>
                sector.id === currentSector.id
                    ? {
                          ...sector,
                          [editType === 'begin' ? 'beginAngle' : 'endAngle']: inputValue || '...',
                      }
                    : sector
            );
            setGunsectors(updatedSectors);
            setModalOpen(false);
        }
        let angleInput = (inputValue.replace(',', '.')*1).toFixed(1);
        if (angleInput>359) {angleInput=359};
        if (angleInput<=0 ) {angleInput=1};
        if (editType === 'begin'){
            dispatch(writesettings(parameter?.imei, angleInput, currentSector.id[0]*1+50))
            .then(() => dispatch(getDmImei(parameter?.imei)))
            .catch((error) => console.error('Ошибка при сохранении данных: ', error));
        }
        if (editType === 'end'){
            dispatch(writesettings(parameter?.imei, angleInput, currentSector.id[0]*1+60))
            .then(() => dispatch(getDmImei(parameter?.imei)))
            .catch((error) => console.error('Ошибка при сохранении данных: ', error));
        }
    }

    // Обработка изменения чекбокса
    function handleCheckboxChange(event) {
        const inputvalue =  1 - parameter.FlagGanUse; // Инверсия значения
        const num = 50;
        setChecked(event.target.checked);
        // Отправка данных на сервер
        dispatch(writesettings(parameter?.imei, inputvalue, num))
            .then(() => dispatch(getDmImei(parameter?.imei)))
            .catch((error) => console.error('Ошибка при сохранении данных: ', error));
    }

    return (
        <div className="GunSectorCard">
            {/* Чекбокс переключения использования пушки */}
            <div className="GunSectorUse">
                <label>Использовать концевую пушку</label>
                <Checkbox handleCheckboxChange={handleCheckboxChange} checked={checked} id="checkbox3" />
            </div>
            <TransitionGroup>
            {/* Если чекбокс выключен */}
            {!checked ? (
                <CSSTransition key="warningInfo" timeout={500}
                    classNames='gunOn'>
                <div className='warningGunInfo'>
                <h4>Внимание!</h4>
                    <div className='angleGunInfo'>
                        <p> Текущее положение: </p>
                        <h3>{angleCurrent+'\u00b0'}</h3>
                    </div>
                </div>
                </CSSTransition>
            ) : ( <CSSTransition key="barrierOff" timeout={500} classNames="gunOff">
                <div className='gunUseOff'>
                         <h2>Пушки отключены</h2>
                    </div>
                </CSSTransition>

             )}
                </TransitionGroup>

                <>
                    {/* Заголовок таблицы */}
                    <div className="sektorsHeader">
                        <h3>Угловое расписание. Сектора.</h3>
                        <div className="sectorBeginEnd">
                            <p>№</p>
                            <img className="angleImg" src={angle} alt="Угол" />
                            <p>Начало</p>
                            <span>-</span>
                            <img className="angleImg" src={angle} alt="Угол" />
                            <p>Конец</p>
                        </div>
                    </div>

                    {/* Таблица с секторами */}
                    <div className="sectorsBody">
                        <table>
                            <tbody>
                                <Sectors sectors={gunsectors} onEdit={openModal} />
                            </tbody>
                        </table>
                    </div>
                </>
            

            {/* Модальное окно */}
            <Modal open={modalOpen}>
                <div className="modalHeader">
                    <h3>Введите новое значение</h3>
                    <svg
                        onClick={() => setModalOpen(false)}
                        height="30"
                        width="30"
                        viewBox="0 0 200 200"
                    >
                        <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                    </svg>
                </div>
                <div className="modalInfo">
                    <label>Сектор № {currentSector?.id}</label>
                    <label className="labelInfo">
                        {editType === 'begin' ? ' Угол включения.' : ' Угол выключения.'}
                    </label>
                    <h4 className="labelInfoQuet">
                        Желаете установить {inputValue + '\u00b0' || '...'}?
                    </h4>
                </div>
                <div className="contentControl">
                    <input
                        type="text"
                        className="input"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <div className="modalButton">
                        <Button onClick={() => setModalOpen(false)}>Отмена</Button>
                        <Button onClick={saveSectorValue}>Сохранить</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
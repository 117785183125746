import {useDispatch } from 'react-redux';
import { useState ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { getUsers,deluser,getDms,edituser } from '../../redux/actions';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import redakt from '../../img/redaktirovat.png'; 
import delet from '../../img/udalit.png';
import './UserSummary.css';

export default function UserSummary({user, dmUser}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userSummaryInfo, setUserSummaryInfo ] = useState({
        username: '...',
        role: 0,
        userId: 0,
        surname: '...',
        name: '...',
        patronymic: '...',
    });

    const [userNewInfo, setUserNewInfo] = useState ({
        usernameNew: user?.username || '...',
        surnameNew: user?.surname || '...',
        nameNew: user?.name || '...',
        patronymicNew: user?.patronymic || '...',
        emailNew: user?.email || '...',
    })

    //проверка входных данных
    useEffect(() => {
        setUserSummaryInfo({
        username: user?.username || '...',
        role: user?.role || 0,
        userId :user?.id || 0,
        surname: user?.surname || '...',
        name: user?.name || '...',
        patronymic: user?.patronymic || '...',
        email: user?.email || '...',
        })
    },[user]);

    
    const [action, setAction] = useState('');
    const [modal, setModal] = useState(false)

    function onClickWinOpen(value) {
        setAction(value);
        setModal(true);
    }
    async function onClickActionSave(value) {
        if (action === 'delet') {await dispatch(deluser(value))}
        else if (action==='edit') {
            await dispatch(edituser(value,userNewInfo.usernameNew,userNewInfo.surnameNew,userNewInfo.nameNew,userNewInfo.patronymicNew))
            // console.log('redakt user');
            // console.log(value);
            // console.log('usernameNew');
            // console.log(userNewInfo.usernameNew);
            // console.log('surnameNew');
            // console.log(userNewInfo.surnameNew);
            // console.log('nameNew');
            // console.log(userNewInfo.nameNew);
            // console.log('patronymicNew');
            // console.log(userNewInfo.patronymicNew);
            // console.log('emailNew');
            // console.log(userNewInfo.emailNew);

        }
        await dispatch(getUsers());
        await dispatch(getDms());
        setModal(false);
    }

    const handleClick = (imei) => {
        // e.preventDefault();
        console.log(imei)
        navigate(`/dmdetal/${imei}`);
      };
    return(
        <>
        <div className='userCard'>
            <div className='userCardHeader'>
                <div className='userCardHeaderName'> 
                    <h3>{userSummaryInfo.username}</h3>
                    {dmUser.length!=0 && <span>{dmUser.length}</span>} 
                </div>   
                <div className=' userCardHeaderBtn'>
                    <button onClick={()=>onClickWinOpen('edit')} className='userBtn'>
                        <img src={redakt} alt="Result" /> 
                    </button>
                    
                    {userSummaryInfo.role===2 ? (
                    <button onClick={()=>onClickWinOpen('delet')} className='userBtn'>
                        <img src={delet} alt="Result" /> 
                    </button>
                    ):(
                        <span>Admin</span>
                    )}
                </div>
            </div>

            <div className='userCardBodyInfo'> 
                    <h4>{userSummaryInfo.surname+' '+
                    userSummaryInfo.name+' '+
                    userSummaryInfo.patronymic}</h4>
                    <div className='userCardBodyEmail'> 
                        <h4>email:</h4>
                        <span>{userSummaryInfo.email}</span>
                    </div>
            </div>
                    {dmUser.length > 0 ? (
                        <div className='userCardBodyDms'>
                            {dmUser.map((dm, index) => (
                            <div className='dmImeiName' key={index} onClick={()=>handleClick(dm.imei)}>{dm.imei_name}</div>
                            ))}
                        </div>
                        ):(
                        <p className='userCardNotDms'>Нет машин</p>
                        )} 
        </div>  

        <Modal open={modal}>
             <div className='modalHeader'>
                <h3>Подтвердите действие</h3>
                <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                    <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                </svg> 
            </div>
            <div className='userLabelInfo'> 
            <label className='labelInfo'>Вы точно желаете {action==='edit' ? ' изменить данные ' : ' удалить ' }пользователя? </label>
                <h4>Логин: {userSummaryInfo.username}</h4>
                <h4 >ФИО: {userSummaryInfo.surname+' '+userSummaryInfo.name+' '+userSummaryInfo.patronymic}</h4>
            </div>
            {action==='edit' && 
            <>
                <div className='userCardInfo'>
                    <p>Логин</p>
                    <input type='text' className='userCardInfoInput'
                        value={userNewInfo.usernameNew}
                        onChange={e=>setUserNewInfo({...userNewInfo, usernameNew: e.target.value})} />
                        <p>Фамилия</p>
                    <input type='text' className='userCardInfoInput'
                        value={userNewInfo.surnameNew}
                        onChange={e=>setUserNewInfo({...userNewInfo, surnameNew:e.target.value})} />
                        <p>Имя</p>
                    <input type='text' className='userCardInfoInput'
                        value={userNewInfo.nameNew}
                        onChange={e=>setUserNewInfo({...userNewInfo, nameNew:e.target.value})} />
                        <p>Отчество</p>
                    <input type='text' className='userCardInfoInput'
                        value={userNewInfo.patronymicNew}
                        onChange={e=>setUserNewInfo({...userNewInfo, patronymicNew:e.target.value})} />
            </div>
                    <h4 className='userCardQuest'>Сохранить данные?</h4></>
            }

            
            <div className='contentControl'>
             <div className='modalButton' >
                        <Button onClick={() => setModal(false)}>Отмена</Button>
                        <Button onClick={()=>onClickActionSave(userSummaryInfo.userId)}>Да</Button>
             </div>
             </div>
        </Modal>
    </>
    )
}
import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../redux/actions';
import show from '../../img/glaz.png';
import noshow from '../../img/nevidim.png';
import './RegisterPage.css'

export default function RegisterPage() {
    const [username, setUsername] = useState('');
    const [surname, setSurname] = useState('');
    const [name, setName] = useState('');
    const [patronymic, setPatronymic] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordTo, setPasswordTo] = useState('');
    const [hasError, setHasError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTo, setShowPasswordTo] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const error = useSelector(state => state.auth.error);
    const handleSubmit=(e)=>{
         e.preventDefault();
         setHasError(true);
         const emailRegex=/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if ((username!= '') &&
            (surname!= '') &&
            (name!= '') &&
            (patronymic!= '') &&
            (email!= '') &&
            (emailRegex.test(email)) &&
            (password!= '') &&
            (passwordTo!= '') && 
            (password===passwordTo)) {
         dispatch(registerUser(username, email, password,surname,name,patronymic));
         setHasError(false);
        //  alert(`Вы успешно зарегистрировались! Подтвердите регистрацию по ссылке в почте.`);
         navigate('/');
        } 
    }

    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
      };

    const togglePasswordToVisibility = (e) => {
        e.preventDefault();
        setShowPasswordTo(!showPasswordTo);
      };
    return(
       
         <form className='RegisterPage' onSubmit={handleSubmit}> 
         <div className='RegisterCard'> 
             <div className='RegisterCardTitle'>
                 <h2>Регистрация</h2>
                 {hasError && <h3 style={{color:'red'}}>ПРОВЕРЬТЕ ДАННЫЕ!</h3>}
             </div> 
        <div className='RegisterCardBody'> 
        
             <div className='RegisterCardBlock'> 
                 <label className='RegisterCardLable'>Логин:</label>
                 <input type='text'className='RegisterCardInput'
                 value={username}
                 onChange={(e)=>setUsername(e.target.value)}></input> 
             </div>

             <div className='RegisterCardBlock'> 
                 <label className='RegisterCardLable'>Фамилия:</label>
                 <input type='text'className='RegisterCardInput'
                 value={surname}
                 onChange={(e)=>setSurname(e.target.value)}></input> 
             </div>

             <div className='RegisterCardBlock'> 
                 <label className='RegisterCardLable'>Имя:</label>
                 <input type='text'className='RegisterCardInput'
                 value={name}
                 onChange={(e)=>setName(e.target.value)}></input> 
             </div>

             <div className='RegisterCardBlock'> 
                 <label className='RegisterCardLable'>Отчество:</label>
                 <input type='text'className='RegisterCardInput'
                 value={patronymic}
                 onChange={(e)=>setPatronymic(e.target.value)}></input> 
             </div>

             <div className='RegisterCardBlock'> 
                 <label className='RegisterCardLable'>Email:</label>
                 <input type='text' className='RegisterCardInput'
                 value={email}
                 onChange={(e)=>setEmail(e.target.value)}></input> 
             </div>

             <div className='RegisterCardBlock'> 
                <div className='passwordVisibility'>
                    <label className='RegisterCardLable'>ПАРОЛЬ:</label>
                    <button onClick={togglePasswordVisibility} className='btnVisibility'>
                        <img src={showPassword ? show : noshow} alt="Result" />  
                    </button>
                </div>
                 <input type={showPassword ? 'text' : 'password'}
                 className='RegisterCardInput'
                 value={password}
                 onChange={(e)=>setPassword(e.target.value)}></input> 
             </div>

            <div className='RegisterCardBlock'>
                <div className='passwordVisibility'>
                    <label className='RegisterCardLable'>Повторите ПАРОЛЬ:</label>
                    <button onClick={togglePasswordToVisibility} className='btnVisibility'>
                        <img src={showPasswordTo ? show : noshow} alt="Result" />  
                    </button>
                </div>
                <input type={showPasswordTo ? 'text' : 'password'}
                className='RegisterCardInput'
                value={passwordTo}
                onChange={(e)=>setPasswordTo(e.target.value)}></input> 
            </div>

        </div>   

             <div className='RegisterCardButton'> 
                 <Link className='RegisterCardLink' to="/">Назад</Link>
                 <button className='RegisterCardBtnInp'
                  type='submit'>Зарегистрироваться</button>   
             </div>
             {error && <p>{error}</p>}
         </div>
     </form>
    )
}
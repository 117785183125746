import {logOut,getUserData} from '../../../redux/actions';
import {Link} from 'react-router-dom'
import './Navbar.css';
import logo from './logo.svg';
import logout from './logout.png';
import React, {useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { useNavigate, useLocation} from "react-router-dom";

 const Navbar=()=>{
    const navigate = useNavigate();
    const location = useLocation();
    const error = useSelector((state) => state.auth.error);
    const role = useSelector((state) => state.userdata?.role);
    const token = useSelector((state) => state.auth.token) || localStorage.getItem('token');
    const username = useSelector((state) => state.userdata?.username);
    const showBtnLk = location.pathname === "/";
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                await dispatch(getUserData()); // Асинхронный вызов через redux-thunk
            } catch (error) { console.error('Error fetching user data:', error);}
        };
        fetchUserData();
    }, [token, role, error,username]);

    const parametr =useSelector((state) => state?.userdata);
    console.log("Navbar parametr");
    console.log(parametr);
    const handleClickOut = () => {
        dispatch(logOut());
    };
    const handleClickLk = (e) => {
        console.log('назад в ЛК');
        e.preventDefault();
        const link= "/dms";
        // const link=role===1 ? "/admindms" : "/userdms";
        navigate(link);
    };

    return(
        
        <div className='navbar'>
                <Link to="/" className='navbarLogo'> 
                    <img src={logo} alt="Result" className='navbarLogoImg'/> 
                     <h3>dm-control.ru</h3>
                </Link>
            <div className='navbarBlock'>
            {role ? (
            <>  
           
                <div className='navbarInfo'> 
                    <div className='navbarInfoRole'>
                        <h4>{role===1 ? 'Администратор:' : 'Пользователь:'}</h4>
                    </div>
                    <div className='navbarInfoUsername'>                       
                        {username}
                    </div> 
                </div> 
             {showBtnLk && <button className='navbarBtnLK' onClick={handleClickLk}>ЛК</button>}    
                 <div className='navbarOut'> 
                    <button className='navbarBtnOut' onClick={handleClickOut}>
                        <img src={logout} alt="Result" className='navbarLogout'/> 
                    </button>
                </div>  
            </>     
             ) : ( 
                <Link to="/login" className='navbarLkIn'>Вход</Link>
            ) }
            </div>
        </div>
    )
}


export default Navbar;

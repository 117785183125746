import {useSelector } from 'react-redux';
import './DmNetwork.css'
import { useState ,useEffect} from 'react'

export default function DmNetwork() {

    const [networkInfo, setNetworkInfo] = useState({
        phone: '...',
        operator_name: '...',
        balans: '...'
      });

    const parameter = useSelector((state) => state.dmimei?.data[0]);

    useEffect(() => {
        setNetworkInfo({
          phone: parameter?.Phone || '...',
          operator_name: parameter?.operator_name || '...',
          balans: parameter?.balans || '...'
        });
      }, [parameter]);

    return(
        <div className="dmNetworkCard">
            <div className='dmNetworkCardHeader'> 
                <h4>Сеть</h4>
            </div>
            <div className="dmNetworkOperator">
                <span>Оператор: 
                    <span>{networkInfo.operator_name==="default" ? '...' : networkInfo.operator_name}</span>
                    </span>
            </div>
            <div className="dmBalanse">
                <span>Баланс: {networkInfo.balans}р</span>
            </div>
            <div className="dmNamberFone">
                <span>Номер sim-карты: {networkInfo.phone && networkInfo.phone.length > 0 && networkInfo.phone[0]==='9' ? (8+networkInfo.phone) : ('...')}
                </span>
            </div>


        </div>
    )
}
const initialState = {
    users: null,
    loading: false,
    error: null
  };
  
  const usersReducer = (state = initialState, action) => {

    switch (action.type) {
      case 'FETCH_USERSDATA_REQUEST':
        return { 
          ...state, 
          loading: true, 
          error: null };
      case 'FETCH_USERSDATA_SUCCESS':
        console.log("FETCH_USERSDATA_SUCCESS");
        console.log(action?.payload);
        return { 
          ...state, 
          users: action.payload,
          loading: false, 
          error: null };
      case 'FETCH_USERSDATA_FAILURE':
        return { ...state, loading: false, error: action.error };
        case 'FETCH_USERSDATA_CLEAR':
          return initialState; // Сбрасываем состояние к изначальному
      default:
        return state;
    }
  };
  
  export default usersReducer;
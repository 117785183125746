import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import show from '../../img/glaz.png';
import noshow from '../../img/nevidim.png';
import './LoginPage.css';


export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [authError, setAuthError] = useState('');
  const [hasError, setHasError] = useState (false);

  const error = useSelector((state) => state.auth.error);
  const role = useSelector((state) => state.userdata?.role);
  const token = useSelector((state) => state.auth.token) || localStorage.getItem('token');
   useEffect(() => {
    const token = localStorage.getItem('token');
     if (token) {
       dispatch({ type: 'LOGIN_SUCCESS', payload: { token } });
     };
   }, [dispatch,token]);

   useEffect(() => {
    if (token && (role === 1 || role === 2)) {
      navigate('/dms');
    } else if (error) {
      setAuthError(error);
      dispatch({ type: 'REGISTER_CLEAR' });
    }
  }, [token, role, navigate, error]);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleSubmit  = (e) => {
    e.preventDefault();
    console.log('error');
    console.log(error);
    setHasError(false);
    if ((username!='') && (password!='')) {
    dispatch(loginUser({ username, password }));
    } else {
    setHasError(true);
    }
  };

  return (
  <div className='loginPage'>
    <form className='loginCard' onSubmit={handleSubmit}> 
      <h2 className='loginCardTitle'>Авторизация</h2>
      
      <div className='loginCardBlock'> 
        <label className='loginCardLable'>Логин:</label>
        <input type='text' className='loginCardInput'
        style={{borderColor: hasError ? 'red' : '#d5d5d5'}} 
        placeholder="Введите логин" value={username}
        onChange={(e)=>setUsername(e.target.value)}></input> 
      </div>
      <div className='loginCardBlock'> 
        <div className='passwordVisibility'> 
            <label className='loginCardLable'>Пароль:</label>
            <button onClick={togglePasswordVisibility} className='btnVisibility'>
                <img src={showPassword ? show : noshow} alt="Result" />  
            </button> 
        </div>
        <input type={showPassword ? 'text' : 'password'} className='loginCardInput' 
        style={{borderColor: hasError ? 'red' : '#d5d5d5'}}
        placeholder="Введите пароль" value={password} 
        onChange={(e)=>setPassword(e.target.value)}></input> 
      </div>
      <div className='loginCardButton'> 
        <Link className='loginCardLink' to="/register">Регистрация</Link>
        <button className='loginCardBtnInp'
        type='submit'>Вход</button>   
      </div>
        {authError && <div>
          <p>{authError}</p>
          <Link className='loginCardLink' to="/restore">Забыли пароль?</Link>
         </div>
        }     
    </form>
  </div>
  );
};

import React, {useEffect} from 'react';
import { getUserData} from '../../../redux/actions';
import {Link} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux';
import { useNavigate, useLocation} from "react-router-dom";
import './FooterNav.css';

export default function FooterNav() {
    const navigate = useNavigate();
    const location = useLocation();
   
    const role = useSelector((state) => state.userdata?.role);
    const dispatch = useDispatch();
    // useEffect(() => {
    //     const fetchUserData = async () => {
    //         try {
    //             await dispatch(getUserData()); // Асинхронный вызов через redux-thunk
    //         } catch (error) { console.error('Error fetching user data:', error);}
    //     };
    //     fetchUserData();
    // }, [token, role, error,username]);

    const parametr =useSelector((state) => state?.userdata);
    console.log("FooterNav parametr");
    console.log(parametr);
    console.log('role');
    console.log(role);
    return(
        <>
        {role===1 && 
        <div className='footerNav'>
            <Link to='/dms' className='navLink'>Все машины</Link>
            <Link to='/imeis' className='navLink'>IMEI</Link>
            <Link to='/users' className='navLink'>Пользователи</Link>
        </div>}
        </>
    )
}


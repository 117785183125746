import React, { useState,useEffect } from 'react';
import {Link, useNavigate,useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { restorepassword,changepassword } from '../../redux/actions';
import show from '../../img/glaz.png';
import noshow from '../../img/nevidim.png';
import './RestorePage.css'

export default function RegisterPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordTo, setPasswordTo] = useState('');
    const[sendtoken,setSendtoken]=useState('');
    const [hasError, setHasError] = useState(false);
    const[isrestore,setIsrestore]=useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTo, setShowPasswordTo] = useState(false);

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      if (token) {
        console.log('Token:', token);
        setIsrestore(true);
        setSendtoken(token);
      } else {
        console.log('Token not found');
        setIsrestore(false);
      }
    }, [location]);

    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
      };

    const togglePasswordToVisibility = (e) => {
        e.preventDefault();
        setShowPasswordTo(!showPasswordTo);
      };

    const handleSubmitEmail=(e)=>{
        e.preventDefault();
        setHasError(true);
        const emailRegex=/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if((email!='') && (emailRegex.test(email)))  {
            dispatch(restorepassword(email)); 
            setHasError(false);
            navigate('/')
        }   
    }

    const handleSubmitNewPassword=(e)=>{
         e.preventDefault();
         setHasError(true);
        if ((password!= '') &&
            (passwordTo!= '') && 
            (password===passwordTo)) {
         dispatch(changepassword(password,sendtoken));
         setHasError(false);
         navigate('/');
        } 
    }
    return(
        <> 
    {isrestore ? 
        (
         <form className='RestorePage' onSubmit={handleSubmitNewPassword}> 
         <div className='RestoreCard'> 
             <div className='RestoreCardTitle'>
                 <h2>Задать новый пароль</h2>
                 {hasError && <h3 style={{color:'red'}}>ПРОВЕРЬТЕ ДАННЫЕ!</h3>}
             </div> 

             <div className='RestoreCardPassword'> 
                <div className='passwordVisibility'> 
                    <label className='RestoreCardLable'>ПАРОЛЬ:</label>
                    <button onClick={togglePasswordVisibility} className='btnVisibility'>
                        <img src={showPassword ? show : noshow} alt="Result" />  
                    </button>
                 </div>
                 <input type={showPassword ? 'text' : 'password'} className='RestoreCardInput'
                  style={{borderColor: hasError ? 'red' : '#d5d5d5'}}
                  placeholder="Введите новый пароль"
                 value={password}
                 onChange={(e)=>setPassword(e.target.value)}></input> 
             </div>

            <div className='RestoreCardPassword'>
                <div className='passwordVisibility'> 
                    <label className='RestoreCardLable'>Повторите ПАРОЛЬ:</label>
                    <button onClick={togglePasswordToVisibility} className='btnVisibility'>
                        <img src={showPasswordTo ? show : noshow} alt="Result" />  
                    </button>
                </div>
                <input type={showPasswordTo ? 'text' : 'password'} className='RestoreCardInput'
                style={{borderColor: hasError ? 'red' : '#d5d5d5'}}
                 placeholder="Повторите еще разок"
                value={passwordTo}
                onChange={(e)=>setPasswordTo(e.target.value)}></input> 
            </div>   

             <div className='RestoreCardButton'> 
                 <button className='RestoreCardBtnInp'
                  type='submit'>Сохранить новый пароль</button>   
             </div>
         </div>
     </form>) :
        (<form className='RestorePage' onSubmit={handleSubmitEmail}> 
            <div className='RestoreCard'>
            <div className='RestoreCardTitle'>
                 <h2>Введите почту</h2>
                 {hasError && <h3 style={{color:'red'}}>ПРОВЕРЬТЕ ДАННЫЕ!</h3>}
             </div>
                <div className='RestoreCardPassword'> 
                    <label className='RestoreCardLable'>Email:</label>
                    <input type='text' className='RestoreCardInput'
                     style={{borderColor: hasError ? 'red' : '#d5d5d5'}}
                     placeholder="Введите почту"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}></input> 
                </div>
                <div className='RestoreCardButton'> 
                    <Link className='RestoreCardLink' to="/">Назад</Link>
                    <button className='RestoreCardBtnInp'
                    type='submit'>Отправить</button>   
                    
                </div>
            </div>
        </form>)
     }
     </>
    )
}
import DmPassport from './DmPassport/DmPassport'
import DmGNSS from './DmGNSS/DmGNSS'
import DmPressure from './DmPressure/DmPressure'
import DmSufety from './DmSufety/DmSufety'
import './DmConfig.css'

export default function DmConfig() {
    console.log('DmConfig RENDER');
    return(
        <div className="dmConfigCard">  
           <DmPassport  />
           <DmGNSS  />
           <DmPressure  />
           <DmSufety />
        </div>
    )
} 
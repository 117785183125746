import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDmImei, writesettings } from '../../../../redux/actions';
import Checkbox from '../../../UI/Checkbox/Checkbox';
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'
import '../DmConfig.css'


export default function DmPressure() {
    // Получаем текущее значение параметра из Redux
    const parameter = useSelector((state) => state.dmimei.data[0]);
    const dispatch = useDispatch();

    // Вынесем состояние модалки в контекст или верхний уровень компонента
    const [modalState, setModalState] = useState({
        isOpen: false,
        paramName: '',
        inputValue: '',
        hasError: false,
        currentIndex: null
    });

     // Оптимизированное получение параметров давления
     const pressConf = useMemo(() => [
        parameter?.PMax || '...',
        parameter?.VaterUstP || '...',
        parameter?.TimeUst1 || '...'
    ], [parameter?.PMax, parameter?.VaterUstP, parameter?.TimeUst1]);

    const nameParam = useMemo(() => [
        "Диапазон датчика давления, Бар",
        "Остановить ДМ при давлении ниже, Бар",
        "Таймер сухого хода, мин",
    ], []);

    // Оптимизированный обработчик для чекбокса
    const handleCheckboxChange = useCallback(async (event) => {
        const inputvalue = 1 - parameter?.PUse;
        const num = 5;
        setCheckedPuse(event.target.checked);

        try {
            await dispatch(writesettings(parameter?.imei, inputvalue, num));
            await dispatch(getDmImei(parameter?.imei));
            
            // Дополнительная запись флага в команды
            if (num === 5) {
                await dispatch(writesettings(parameter?.imei, inputvalue + 1, num + 30));
                await dispatch(getDmImei(parameter?.imei));
            }
        } catch (error) {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        }
    }, [parameter?.imei, parameter?.PUse, dispatch]);

    // Унифицированный обработчик модального окна
    const handleModalOpen = useCallback((paramName, index) => {
        setModalState({
            isOpen: true,
            paramName,
            inputValue: '',
            hasError: false,
            currentIndex: index
        });
    }, []);

    const handleModalClose = useCallback(() => {
        setModalState(prev => ({ ...prev, isOpen: false }));
    }, []);

    const handleInputChange = useCallback((e) => {
        setModalState(prev => ({ ...prev, inputValue: e.target.value }));
    }, []);

    // Оптимизированная функция сохранения
    const handleSave = useCallback(async () => {
        const { currentIndex, inputValue } = modalState;
        
        if (!inputValue) {
            setModalState(prev => ({ ...prev, hasError: true }));
            return;
        }

        try {
            let value = inputValue.replace(',', '.');
            let processedValue = value;
            
            if (!isNaN(value) && value.trim() !== '') {
                if (currentIndex === 0 || currentIndex === 2) {
                    processedValue = Math.round(value * 1);
                } else if (currentIndex === 1) {
                    processedValue = Math.round(value * 10) / 10;
                }
            } else {
                processedValue = pressConf[currentIndex];
            }

            await dispatch(writesettings(parameter?.imei, processedValue, 30 + currentIndex));
            await dispatch(getDmImei(parameter?.imei));

            // Дополнительная логика для разных параметров
            if (currentIndex === 1) {
                const minADC = 800;
                const maxADC = 3000;
                const Pmin = 0;
                const ustADC = minADC + ((processedValue - Pmin) / (parameter?.PMax - Pmin)) * (maxADC - minADC);
                await dispatch(writesettings(parameter?.imei, ustADC, 35 + currentIndex));
                await dispatch(getDmImei(parameter?.imei));
            } else if (currentIndex === 2) {
                await dispatch(writesettings(parameter?.imei, processedValue * 60, 35 + currentIndex));
                await dispatch(getDmImei(parameter?.imei));
            }

            handleModalClose();
        } catch (error) {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        }
    }, [modalState, parameter?.imei, parameter?.PMax, pressConf, dispatch, handleModalClose]);

 // Оптимизированный рендеринг параметров
 const PressParametr = useMemo(() => (
    nameParam.map((text, index) => (
        <div className='ParamInput' key={index}> 
            <label>{text}</label> 
            <Button onClick={() => handleModalOpen(text, index)}>
                <span className='buttonInput'>{pressConf[index]}</span>
            </Button> 
        </div>
    ))
), [nameParam, pressConf, handleModalOpen]);

// Состояние чекбокса
const [checkedPuse, setCheckedPuse] = useState(parameter?.PUse === 0);

// Обновление состояния чекбокса при изменении параметров
useEffect(() => {
    setCheckedPuse(parameter?.PUse === 0);
}, [parameter?.PUse]);

return (
    <div className='cardDm'> 
        <h3>Давление</h3>
        <div className='ParamInput'>
            <label>Активировать защиту от сухого хода</label>   
            <div className='checkboxPress'>
                <Checkbox 
                    handleCheckboxChange={handleCheckboxChange}  
                    checked={checkedPuse} 
                    id="checkbox2" 
                /> 
            </div>
        </div>
        {PressParametr}
         {/* Единое модальное окно */}
         <Modal open={modalState.isOpen}>
                <div className='modalHeader'>
                    <h3>Введите новое значение</h3>
                    <svg onClick={handleModalClose} height="30" viewBox="0 0 200 200" width="30">    
                        <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                    </svg> 
                </div>
                <div className='modalInfo'> 
                    <label className='labelInfo'>{modalState.paramName}</label>
                    <h4 className='labelInfoQuet'>
                        {modalState.hasError ? "Передумали?" : `Желаете установить ${modalState.inputValue}?`}
                    </h4>
                </div>
                <div className='contentControl'>
                    <input 
                        type='number' 
                        className='input' 
                        value={modalState.inputValue} 
                        onChange={handleInputChange}
                        style={{ border: modalState.hasError ? '1px solid red' : null }} 
                    />
                    <div className='modalButton'>
                        <Button onClick={handleModalClose}>Отмена</Button>
                        <Button onClick={handleSave}>Сохранить</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}




import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CircleWithLineAndTriangle from "../../CircleWithLineAndTriangle";
import DmSignal from "../../UI/DmSignal/DmSignal";
import DmLink from "../../UI/DmLink/DmLink";
import DmStatus from "../../UI/DmStatus/DmStatus";
import DmGun from '../../UI/DmGun/DmGun';
import DmRezIn from '../../UI/DmRezIn/DmRezIn';
import PressureSensor from "../../UI/PressureSensor/PressureSensor";
import AnglePosition from "../../UI/AnglePosition/AnglePosition";
import './DmInfo.css';

export default function DmInfo() {
    const parameter = useSelector((state) => state.dmimei?.data[0]);
    const [dmInfo, setDmInfo ] = useState({
        signalValue: '...',
        param_last_date: 0,
        status: '...',
        angleCurrent: '...',
        adc: 0,
        pmax: 0,
    });
    
    //проверку входных данных
    useEffect(() => {
        setDmInfo({
        signalValue: parameter?.Sig_Value || '...',
        param_last_date: parameter?.param_last_date || 0,
        status: parameter?.param4 || '...',
        angleCurrent: parameter?.AngleCurrent || '...',
        adc: parameter?.param3 || 0,
        pmax: parameter?.PMax || 0,
        })
        
    },[parameter]);
   
    const OFFLINE_THRESHOLD_MINUTES = 5; // Порог оффлайна в минутах
    const serverTime = new Date(dmInfo.param_last_date);
    const browserTime = new Date();
    const diffInMilliseconds = Math.abs(browserTime - serverTime);
    const diffInMinutes = Math.floor(diffInMilliseconds/(1000*60));
    const islink = diffInMinutes > OFFLINE_THRESHOLD_MINUTES ? 'Offline' : 'Online';

    return(

        <div className="dmCardInfo">
            <div className="cardInfoHeader">
                <DmSignal signalValue={dmInfo.signalValue} param_last_date={dmInfo.param_last_date} />
                <DmLink islink={islink} />
            </div>
            <div className="cardInfoBody">
                <div className="cardInfoBodyCircle">
                    <CircleWithLineAndTriangle data={parameter} islink={islink} />
                </div>
                <div className="InfoBodyBlock">
                    <DmStatus status={dmInfo.status} islink={islink} />
                    <div className="InfoParam"> 
                    <AnglePosition angleCurrent={dmInfo.angleCurrent} />
                    <PressureSensor adc={dmInfo.adc} pmax={dmInfo.pmax} islink={islink} />
                    <DmGun islink={islink} status={dmInfo.status} />
                    <DmRezIn islink={islink} status={dmInfo.status} />
                    </div>
                </div>
            </div>
        </div>
    )
}
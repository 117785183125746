import React, {useEffect,useState} from 'react';
import { useSelector } from 'react-redux';

const CircleWithLineAndTriangle = ({data, islink}) => {

  const checkBit = (num, bitPosition) => {
    return (num & (1 << bitPosition)) !== 0;
  };
  // Параметры окружности 
  const radius = 38;
  const centerX = 40;
  const centerY = 40;
  let angletriangle=0;


  const angleCurrent=data?.AngleCurrent;

  const forward=checkBit(data?.param4, 6);
  const revers=checkBit(data?.param4, 4);
  const nowater=checkBit(data?.param4, 3); //включили режим "без воды"
  const offsetNulN=data?.OffsetNulN;

  // Определяем цвета в зависимости от состояния машины
  const colorCircle = islink==='Online' ? (nowater ? ("#ebfbee"):("#2196f333")) : ("#dedcdc");   
  const colorOutline = islink==='Online' ? (nowater ? ("#92f7a5"):("#1492fac7")) : ("#b8b4b4"); 

  const lineLength = radius; // Длина линии равна радиусу окружности
  const angle = Math.PI * (angleCurrent*1-90+offsetNulN*1)/ 180; // Угол в радианах (45 градусов)
  // Конечная точка линии 1
  const endX = centerX + lineLength * Math.cos(angle);
  const endY = centerY + lineLength * Math.sin(angle);

    // Параметры линии 2
    const angle2 = Math.PI * (offsetNulN-90)/ 180; // Угол в радианах (45 градусов)
    // Конечная точка линии2
    const endX2 = centerX + lineLength * Math.cos(angle2);
    const endY2 = centerY + lineLength * Math.sin(angle2);

  // Параметры треугольника было 20 и 10
  const triangleBase = 15; // Длина основания треугольника
  const triangleHeight = 15; // Высота треугольника

  // Вершины треугольника
    const x1=endX, y1=endY;
    const x2=centerX + (lineLength-triangleBase) * Math.cos(angle),
    y2=centerY + (lineLength-triangleBase) * Math.sin(angle);
   if(revers){
      angletriangle=Math.atan2(y2 - y1, x2 - x1); // Угол меняем 1 и 2 местами чтобы сменить направление
      //setAngletriangle(3);
      console.log('взад');
    }
    if(forward){
      angletriangle= Math.atan2(y1 - y2, x1 - x2); // Угол меняем 1 и 2 местами чтобы сменить направление
     //setAngletriangle(5);
      console.log('вперед');
    }
    //console.log(angletriangle);
    const x3=(x1 + x2) / 2 + (triangleBase / 2) * Math.cos(angletriangle+ Math.PI / 2),
    y3=(y1 + y2) / 2 + (triangleBase / 2) * Math.sin(angletriangle + Math.PI / 2);

  const trianglePoints = [
    x1, y1, // угол примыкающий к окружности 
    x2,y2, // угол между центром круга и окружностью
    x3,y3 // Верхняя точка
  ].join(',');

  //сектор
    const cx=100; // Центр окружности по оси X
    const cy=100; // Центр окружности по оси Y
    const r=45 ;  // Радиус окружности
    const startAngle=0; // Начальный угол сектора
    const endAngle=90; // Конечный угол сектора
    const fill="blue" // Цвет сектора
    // Функция для перевода углов в радианы
    const radians = (degrees) => (degrees * Math.PI) / 180;

    // Функция для получения координат точки на окружности
    const polarToCartesian = (cx, cy, r, angle) => {
      const angleInRadians = radians(angle);
      return {
        x: cx + r * Math.cos(angleInRadians),
        y: cy - r * Math.sin(angleInRadians),
      };
    };
  
    // Координаты начальной и конечной точек сектора
    const start = polarToCartesian(cx, cy, r, endAngle);
    const end = polarToCartesian(cx, cy, r, startAngle);
  
    // Определяем, является ли дуга большой (более 180 градусов)
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  
    // Строка для пути сектора
    const pathData = [
      `M ${cx},${cy}`, // Переместиться в центр окружности
      `L ${start.x},${start.y}`, // Линия от центра к начальной точке сектора
      `A ${r},${r} 0 ${largeArcFlag} 1 ${end.x},${end.y}`, // Дуга от начальной точки к конечной
      'Z', // Закрыть путь (линия от конечной точки обратно к центру)
    ].join(' ');

  return (
    <svg width={80} height={80}>
      {/* Окружность */}
      <circle
        cx={centerX}
        cy={centerY}
        r={radius}
        stroke={colorOutline}
        strokeWidth={2}
        fill={colorCircle}
      />
       
      {/* Сектор */}
      {/*<circle 
        cx={cx} 
        cy={cy} 
        r={r} 
        fill={colorCircle} />
      <path 
        d={pathData} 
        fill={fill} />*/}

      {/* Линия от центра до края окружности нулевое положение*/}
      <line
        x1={centerX}
        y1={centerY}
        x2={endX2}
        y2={endY2}
        stroke="yellow"
        strokeWidth={3}
      />
      {/* Линия от центра до края окружности основная линия - траектория*/}
      <line
        x1={centerX}
        y1={centerY}
        x2={endX}
        y2={endY}
        stroke="black"
        strokeWidth={2}
      />
      {/* Центр окружности - точка */}
      <circle
        cx={centerX}
        cy={centerY}
        r={2}
        stroke={"#000000"}
        strokeWidth={1}
        fill={"#000000"}
      />
      {/* Треугольник на конце линии */}
      {islink==='Online' && revers !== forward && (<polygon
        points={trianglePoints}
        fill="green"
        stroke="black"
        strokeWidth={1}
      />)
      }     
    </svg>
  );
};

export default CircleWithLineAndTriangle;
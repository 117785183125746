import React, {useEffect,useState} from 'react';
import { useParams, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { getDmImei, getDms } from '../../redux/actions';
import DmInfo from './DmInfo/DmInfo';
import DmCommands from './DmCommands/DmCommands';
import DmNetwork from './DmNetwork/DmNetwork';
import DmConfig from './DmConfig/DmConfig';
import CircleYandex from "../CircleYandex";
import DmGunSectors from './DmGunSectors/DmGunSectors';
import DmBarrier from './DmBarrier/DmBarrier';
import back from '../../img/nazad.png'
import mapBtn from '../../img/mishen.png'
import './DmDetals.css'


function DmDetalsHeader({onClickPage, onClickBuck, onClickMup, imei_name}) {
  const arOptions = ['Управление', 'Настройка', 'Барьеры', 'Пушки'];
  const [value, setValue] = useState('0');
  const options = arOptions.map((text, index) => {
            return <option key={index} value={index}>{text}</option>;          
        });
  
            return(
            <div className="detalsHeader">
                  <div className="detalsHeaderName">
                     <h2>{imei_name}</h2>
                  </div>
                  <div className="headerNavigator"> 
                      <div className="headerNavigatorBack">
                      <button onClick={onClickBuck}> <img src={back} alt="Result" /> </button>
                       </div>
                      <div className="headerNavigatorSelect">
                      <select
                            value={value}
                            onChange={(event) => {
                              const selectedValue = event.target.value;
                              setValue(selectedValue);
                              onClickPage(selectedValue);  // Вызываем onClickPage с новым значением
                              }}
                            >
                           {options}</select>     
                      </div>
                        <div className="headerNavigatorMap">
                          <button onClick={onClickMup}> <img src={mapBtn} alt="Result" /> </button>
                        </div>          
                  </div>
              </div>
             ) 
          }

  export default function DmDetals() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const imei=id;
    const navigate = useNavigate();
    const role = useSelector((state) => state.userdata?.role);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);// Состояние для отслеживания ширины окна
    const token = localStorage.getItem('token'); // Предположим, что токен хранится в localStorage
  
  useEffect(() => {// Функция, которая будет обновлять ширину окна при изменении размера
    const handleResize = () => {setWindowWidth(window.innerWidth)};
    // Добавляем слушатель изменения размера окна
    window.addEventListener('resize', handleResize);
    // Убираем слушателя при размонтировании компонента
    return () => {window.removeEventListener('resize', handleResize)};
    }, []);

  useEffect(() => {
            if (token === null) {
              navigate('/'); // Перенаправление на страницу авторизации
            }
          }, [token, navigate]);

  // useEffect(() => {
  //   const fetchGetDms = async () => {
  //     try { await dispatch(getDms());
  //     } catch (error) { console.error('Ошибка при получении данных:', error);}
  // };
  //   const fetchGetDmImei = async () => {
  //     try { await dispatch(getDmImei(imei));
  //     } catch (error) { console.error('Ошибка при получении данных:', error);}
  // };
  //         fetchGetDms();
  //         fetchGetDmImei();
  //   const intervalId = setInterval(fetchGetDmImei, 5000); 
      // Очищаем интервал при размонтировании компонентаs
    // return () => clearInterval(intervalId);
    //         }, []);  

    useEffect(()=>{
      const fetchGetDmImei = () => {
        dispatch(getDmImei(imei));}
        fetchGetDmImei();
        const intervalId = setInterval(fetchGetDmImei, 5000);
        return () => clearInterval(intervalId);
    },[imei])

    useEffect(()=>{
      const fetchGetDms = () => {
        dispatch(getDms());}
        fetchGetDms();
        const intervalId = setInterval(fetchGetDms, 10000);
        return () => clearInterval(intervalId);
    },[imei])

  const data = useSelector((state) => state.dmimei?.data[0]);
  //console.log(data);
  const datain = useSelector((state) => state);
  //console.log(datain);

  const [page, setPage] = useState('0');
    function onClickPage(index) {          
    setPage(index);           
  } 
  const [clickMup, setClickMup]=useState(false);
  function onClickBuck(e) {
    e.preventDefault();
    const link= "/dms";
    navigate(link);
  } 
  function onClickMup(e) {
    e.preventDefault();
    if (windowWidth < 768) {
      // Логика для экранов с шириной меньше 768px (мобильные устройства)
      setClickMup(true);
    }else {
      // Логика для экранов с шириной больше 1024px (например, десктопы)
    } 
  }
  function onClickBuckMup(e) {
    e.preventDefault();
    console.log('Ширина экрана меньше 768px: выполнено действие 1');
      setClickMup(false);
      console.log('закрываем карты'+clickMup)
  }

  return (
    <>
    <div className='mainCard'> 
    {(clickMup) ? (
      <div className='mainMupMini'>
        <div className='MupMiniHeader'> 
          <div className='headerNavigatorBuckMap'> 
            <button onClick={onClickBuckMup} ><img src={back} alt="Result" /></button>
          </div>
          <h3>{data?.imei_name}</h3>
        </div>
      <CircleYandex datain={datain} />
      </div> 
    ) : (
        <div className='dmDetalsCard'>
        <DmDetalsHeader onClickPage={onClickPage} onClickBuck={onClickBuck} onClickMup={onClickMup}
            imei_name={data?.imei_name} />
           <div className='dmDetalMain'>
            {page==='0' &&  
              <>
                  <DmInfo />
                  <DmCommands />
                  <DmNetwork />
              </>
              }
            {page==='1' && <DmConfig />}  
            {page==='2' && <DmBarrier />} 
            {page==='3' && <DmGunSectors />} 
            </div>
            <div className='dmConfigCardFooter'>
              <span>imei: {data?.imei}</span>
            </div>
        </div>
    ) }
          <div className='mainMup'>
          <CircleYandex datain={datain} />
          </div>
     </div>
    </>
  );
};